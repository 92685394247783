<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>笔记话题</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="话题名称">
                                <el-input v-model="search.topic"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                                   @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                                   @click="issearch = !issearch">显示搜索
                        </el-button>
                        <el-button v-if="is_auth('note.topic.issave')" icon="el-icon-plus"
                                   size="medium" type="primary" @click="add_page">添加
                        </el-button>
                        <el-button
                            icon="el-icon-download"
                            size="medium"
                            type="primary"
                            @click="exportList"
                            >导出
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    v-loading="loading"
                    :data="tableData"
                    border
                    size="medium"
                    style="width: 100%">
                <el-table-column
                        prop="topic"
                        label="笔记话题名称"
                        width="180">
                    <template slot-scope="scope">
                        <el-tag class="tag_colour-tag" effect="dark" size="mini" :color="scope.row.topic">
                            {{ scope.row.topic }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="colour"
                        label="笔记话题颜色"
                        width="180">
                    <template slot-scope="scope">
                        <div class="tag_colour" type="success" :style="get_tag_colour(scope.row.colour)">
                            {{ scope.row.colour }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="describe"
                        label="笔记话题封面"
                >
                    <template slot-scope="scope">
                        <div v-if="!is_empty(scope.row.cover)">
                            <el-image
                                    style="width: 22px; height: 22px; margin-right: 4px;"
                                    :src="scope.row.cover"
                                    fit="contain"
                                    :preview-src-list="[scope.row.cover]">
                            </el-image>
                        </div>
                        <div v-else>
                            无封面
                        </div>
                    </template>

                </el-table-column>

                <el-table-column
                        prop="describe"
                        label="笔记话题描述"
                >
                </el-table-column>
                <el-table-column
                        prop="see_sum"
                        label="围观数量"
                >
                <template slot-scope="scope">
                    <span v-if="is_auth('note.topic.optimizesee') ">{{
                            scope.row.see_sum
                        }}+{{ scope.row.optimizesee_see_sum }}={{ scope.row.see_sum + scope.row.optimizesee_see_sum}}</span>
                    <span v-else>{{ scope.row.optimizesee_see_sum + scope.row.optimizesee_see_sum }}</span>
                </template>
                </el-table-column>
                <el-table-column
                        prop="quote_sum"
                        label="参与数量"
                >
                    <template slot-scope="scope">
                        <span v-if="is_auth('note.topic.optimizesee') ">{{
                                scope.row.quote_sum
                            }}+{{ scope.row.optimizesee_quote_sum }}={{ scope.row.quote_sum + scope.row.optimizesee_quote_sum}}</span>
                        <span v-else>{{ scope.row.quote_sum + scope.row.optimizesee_quote_sum }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="ishot"
                        label="是否热门"
                >
                    <template slot-scope="scope">
                        <div v-if="scope.row.ishot===1">是</div>
                        <div v-else>否</div>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="update_time"
                        label="添加时间"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="create_time"
                        label="修改时间"
                        width="180">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        label="操作"
                        width="360">
                    <template slot-scope="scope">
                        <el-button
                                v-if="is_auth('note.topic.issave')"
                                @click="edit_page(scope.row)"
                                size="mini">编辑
                        </el-button>
                        <el-button
                                v-if="is_auth('note.topic.sethot') && scope.row.ishot===0"
                                size="mini" type="primary" @click="sethot(scope.row.topic_uuid,1)">设置热门
                        </el-button>
                        <el-button
                                v-if="is_auth('note.topic.sethot') && scope.row.ishot===1"
                                size="mini" type="danger" @click="sethot(scope.row.topic_uuid,0)">取消热门
                        </el-button>
                        <el-button
                                v-if="is_auth('note.topic.isdel')"
                                @click="operation_tip (scope.row.topic_uuid,scope.row.topic,'isdel')"
                                type="danger" size="mini">删除
                        </el-button>
                        <el-button
                                v-if="is_auth('note.topic.optimizesee') "
                                size="mini" type="primary" @click="visits(scope.row)">数据优化
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--编辑-->
            <el-dialog v-loading="loading"
                       v-if="is_auth('note.topic.isdel')"
                       title="笔记话题"
                       :visible.sync="editPage"
                       width="50%"
                       :close-on-press-escape="false"
                       :close-on-click-modal="false"
            >
                <el-row>
                    <el-form size="medium" ref="form" :label-width="this.env.label_width">
                        <el-col :span="24">
                            <el-form-item label="话题名称">
                                <el-input v-model="info.topic"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="话题颜色">
                                <div class="block">
                                    <el-color-picker v-model="info.colour"></el-color-picker>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="话题背景">
                                <Qnupload v-model="info.cover" :sum="1"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="话题描述">
                                <el-input v-model="info.describe" type="textarea" maxlength="20" show-word-limit
                                          rows="3"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form>
                </el-row>
                <el-row style="margin: 10px 0px;">
                    <el-button size="medium" type="primary" @click="save">确 定</el-button>
                    <el-button size="medium" @click="editPage = false">取 消</el-button>
                </el-row>

            </el-dialog>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination
                    @current-change="getlist"
                    :page-size="this.env.pageSize"
                    :pager-count="7"
                    background
                    layout="prev, pager, next, total"
                    :current-page.sync="page"
                    :total="count">
            </el-pagination>
             <!--数据优化-->
            <el-dialog v-if="is_auth('note.topic.optimizesee')"
                       title="数据优化"
                       :visible.sync="visitsVisible"
                       width="40%"
                       :close-on-press-escape="false"
                       :close-on-click-modal="false"
            >
                <el-row>
                    <el-form size="medium" ref="form" label-width="120px">
                        <el-col :span="24">
                            <el-form-item label="话题标题">
                                <span class="form-span-text">{{ info.topic }}</span>
                            </el-form-item>
                            <el-form-item label="当前围观数量">
                                <span class="form-span-text">{{ info.see_sum }}</span>
                            </el-form-item>
                            <el-form-item label="当前参与数量">
                                <span class="form-span-text">{{ info.quote_sum }}</span>
                            </el-form-item>
                            <el-form-item label="优化围观数量">
                                <el-input
                                        onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                                        type="number" v-model="info.optimizesee_see_sum"></el-input>
                            </el-form-item>
                            <el-form-item label="优化参与数量">
                                <el-input
                                        onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                                        type="number" v-model="info.optimizesee_quote_sum"></el-input>
                            </el-form-item>

                        </el-col>
                    </el-form>
                </el-row>
                <el-row style="margin: 10px 0px;">
                    <el-button size="medium" type="primary" @click="topicSave">确
                        定
                    </el-button>
                    <el-button size="medium" @click="visitsVisible = false">取 消</el-button>
                </el-row>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import Qnupload from '@/components/Qnupload'

export default {
    components: {
        Qnupload,
    },
    data() {
        return {
            page_name: '笔记话题',
            issearch: false,
            loading: true,      // 加载状态
            search: {},     // 搜索条件
            count: 0,        // 数据总条数
            tableData: [],// 列表内容
            page: 1,            // 当前页数
            topic_uuid: '',
            info: {}, // 编辑数据
            editPage: false,
            visitsVisible: false,
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 判断是否为空
        is_empty(auth) {
            return this.Tool.is_empty(auth)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            // 设置搜索条件
            this.search = {
                topic: '',
            }
            // 获取数据
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "note.topic.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                console.log('json', json)
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(topic_uuid = '', topic = '', operation = '') {

            let tip = ''
            let options = {}
            // 删除
            if (operation === 'isdel') {
                tip = '删除【' + topic + '】？'
                options = {type: 'error'}
            }

            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                    .then(() => {
                        this.isoperation(topic_uuid, operation)
                    })
                    .catch(() => {
                    });
        },
        // 操作
        isoperation(topic_uuid = '', operation = '') {
            let postdata = {
                api_name: "note.topic." + operation,
                token: this.Tool.get_l_cache('token'),
                topic_uuid: topic_uuid,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 添加页面显示
        add_page() {
            this.topic_uuid = ''
            this.info = {
                topic: '',
                colour: '',
                describe: '',
                cover: '',
            }
            this.editPage = true    // 显示页面
        },
        // 编辑显示
        edit_page(row = {}) {
            this.info = row
            this.topic_uuid = row.topic_uuid
            this.editPage = true    // 显示页面
        },
        // 保存
        save() {
            let postdata = {
                api_name: 'note.topic.issave',
                token: this.Tool.get_l_cache('token'),
                topic: this.info.topic,
                colour: this.info.colour,
                topic_uuid: this.topic_uuid,
                describe: this.info.describe,
                cover: this.info.cover,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.editPage = false
                    this.$message({
                        message: '保存成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()
                        }
                    });

                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 获取标签颜色样式
        get_tag_colour(tag_colour) {
            return `border-color:${tag_colour}; color: ${tag_colour};`
        },
        // 设置热门
        sethot(topic_uuid, ishot) {
            let postdata = {
                api_name: "note.topic.sethot",
                token: this.Tool.get_l_cache('token'),
                topic_uuid,
                ishot,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        visits(row = {}) {
            this.info = row
            this.visitsVisible = true
        },
        topicSave() {
            let postdata = {
                api_name: "note.topic.optimizesee",
                token: this.Tool.get_l_cache('token'),
                topic_uuid: this.info.topic_uuid,
                optimizesee_see_sum: this.info.optimizesee_see_sum,
                optimizesee_quote_sum: this.info.optimizesee_quote_sum,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            // this.page = 1
                            this.info = {}
                            this.visitsVisible = false
                            this.getlist()
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        exportList() {
            let postdata = {
                api_name: "note.topic.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
                export: "yes",
            };
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true;
            this.Tool.post_data("oss", postdata, (res) => {
                if (res.code === 10005) {
                    this.Tool.errormes(res);
                } else {
                    location.href = res;
                }
                this.loading = false;
            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tag_colour {
    height: 28px;
    /*background: #2c3e50;*/
    border: 1px solid;
    text-align: center;
    line-height: 28px;
}

.tag_colour-tag {
    border: 0px;
}
</style>
